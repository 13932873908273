import PropTypes from "prop-types";
import styles from "./PaymentTierThree.module.css";
import { paymentTiersCopy } from "./copy";

const PaymentTierThree = ({ className = "" }) => {
  const { enterprise } = paymentTiersCopy;

  const handleContactUs = () => {
    window.location.href = enterprise.button.url;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleContactUs();
    }
  };

  return (
    <div 
      className={[styles.paymentTierThree, className].join(" ")}
      role="listitem"
      aria-labelledby="enterprise-tier-title"
    >
      <div className={styles.paymentTierThreeChild} role="presentation" />
      <div className={styles.enterpriseContent}>
        <h2 id="enterprise-tier-title" className={styles.enterprise}>{enterprise.title}</h2>
      </div>
      <div className={styles.enterpriseDescription}>
        <b className={styles.customSolutionsAvailableForContainer}>
          {enterprise.description.map((line, index) => (
            <p key={index} className={styles.customSolutionsAvailable}>{line}</p>
          ))}
        </b>
      </div>
      <button 
        className={styles.signUp} 
        onClick={handleContactUs}
        onKeyPress={handleKeyPress}
        aria-label="Contact us about Enterprise plan"
      >
        <div className={styles.contactUs}>{enterprise.button.text}</div>
      </button>
      <div className={styles.security} role="list">
        <div className={styles.planInfo}>
          <b className={styles.unlimitedChatsPerContainer}>
            {enterprise.features.slice(0, 2).map((feature, index) => (
              <p key={index} className={styles.customSolutionsAvailable} role="listitem">{feature}</p>
            ))}
          </b>
          <div className={styles.divider} role="presentation" />
        </div>
        <div className={styles.enterpriseGradeSecurityElnContainer} role="list">
          {enterprise.features.slice(2).map((feature, index) => (
            <p key={index} className={styles.enterpriseGradeSecurity} role="listitem">{feature}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

PaymentTierThree.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierThree;
