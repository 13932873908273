import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./GroupComponent.module.css";
import parse from 'html-react-parser';

const GroupComponent = ({
  className = "",
  propTop,
  propGap,
  question,
  propPadding,
  answer,
}) => {
  const groupDivStyle = useMemo(() => {
    return {
      top: propTop,
      gap: propGap,
    };
  }, [propTop, propGap]);

  const fAQIconsStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <section
      className={[styles.question, className].join(" ")}
      style={groupDivStyle}
      aria-label="FAQ item"
    >
      <b className={styles.whatTypesOf}>{question}</b>
      <div className={styles.fAQContent}>
        <div 
          className={styles.fAQIcons} 
          style={fAQIconsStyle}
          aria-hidden="true"
        >
          <img
            className={styles.labkickIcon}
            loading="lazy"
            alt=""
            src="/labkickicon@2x.png"
          />
        </div>
        <div 
          className={styles.answer}
          role="region"
          aria-label="Answer"
        >
          {parse(answer)}
        </div>
      </div>
    </section>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,

  /** Style props */
  propTop: PropTypes.any,
  propGap: PropTypes.any,
  propPadding: PropTypes.any,
};

export default GroupComponent;
