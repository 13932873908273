import React, { forwardRef } from "react";
import styles from "../pages/Desktop.module.css";
import BenefitItem from "./BenefitItem";
import FrameComponent2 from "./FrameComponent2";

const QuestionsSection = forwardRef(({ className = "" }, ref) => {
  return (
    <section ref={ref} className={`${styles.questions} ${styles.responsiveQuestions}`} aria-label="Questions and Benefits">
      <img
        className={styles.logoBannerIcon}
        loading="lazy"
        alt="LabKick Logo Banner"
        src="/logobanner.svg"
      />
      <BenefitItem />
      <FrameComponent2 />
      <div className={styles.vectorParent} role="presentation">
        <img className={styles.frameChild} alt="" src="/rectangle-107.svg" role="presentation" aria-hidden="true" />
        <img
          className={styles.dynamicLinesIcon}
          alt=""
          src="/dynamic-lines-faq.svg"
          role="presentation"
          aria-hidden="true"
        />
      </div>
    </section>
  );
});

QuestionsSection.displayName = 'QuestionsSection';
export default QuestionsSection;
