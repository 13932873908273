import React, { useState, useEffect, useRef } from 'react';
import styles from './NavDots.module.css';

const NavDots = ({ sectionRefs }) => {
  const [selectedDot, setSelectedDot] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const isScrollingRef = useRef(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsVisible(window.innerWidth > 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    const handleScroll = () => {
      if (!isScrollingRef.current) {
        const scrollPosition = window.scrollY + window.innerHeight / 2;

        for (let i = sectionRefs.length - 1; i >= 0; i--) {
          if (sectionRefs[i].current && scrollPosition >= sectionRefs[i].current.offsetTop) {
            setSelectedDot(i);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [sectionRefs]);

  const scrollToSection = (index) => {
    isScrollingRef.current = true;
    setSelectedDot(index);
    if (sectionRefs[index] && sectionRefs[index].current) {
      const targetPosition = sectionRefs[index].current.offsetTop;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        isScrollingRef.current = false;
      }, 1000);
    }
  };

  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      scrollToSection(index);
    }
  };

  if (!isVisible) return null;

  return (
    <nav 
      className={styles.navDotsContainer}
      aria-label="Page navigation"
    >
      {[0, 1, 2, 3].map((index) => (
        <button
          key={index}
          className={`${styles.dot} ${index === selectedDot ? styles.selected : ''}`}
          onClick={() => scrollToSection(index)}
          onKeyDown={(e) => handleKeyPress(e, index)}
          aria-label={`Scroll to section ${index + 1}`}
          aria-current={index === selectedDot ? 'true' : 'false'}
          tabIndex={0}
        />
      ))}
    </nav>
  );
};

export default NavDots;
