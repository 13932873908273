import React, { forwardRef } from 'react';
import styles from "../pages/Desktop.module.css";
import PaymentTierOne from "../components/PaymentTierOne";
import PaymentTierTwo from "../components/PaymentTierTwo";
import PaymentTierThree from "../components/PaymentTierThree";
import { paymentSectionCopy } from "./copy";

const PaymentSection = forwardRef(({ className = "" }, ref) => {
  return (
    <section ref={ref} className={styles.paymentPanel} aria-labelledby="payment-section-heading">
      <div className={styles.paymentPanel}>
        <div className={styles.paymentPanelChild} role="presentation" />
        <div className={styles.paymentContent}>
          <div className={styles.labStatement}>
            <h1 id="payment-section-heading" className={styles.builtForYour}>{paymentSectionCopy.heading}</h1>
            <p className={styles.whetherYouKick}>
              {paymentSectionCopy.subheading}
            </p>
          </div>
        </div>
        <div className={styles.pricingTiers} role="list">
          <PaymentTierOne />
          <PaymentTierTwo />
          <PaymentTierThree />
        </div>
      </div>
    </section>
  );
});

PaymentSection.displayName = 'PaymentSection';

export default PaymentSection;
