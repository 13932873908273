import PropTypes from "prop-types";
import styles from "./PaymentTierOne.module.css";
import { paymentTiersCopy } from "./copy";

const PaymentTierOne = ({ className = "" }) => {
  const { academic } = paymentTiersCopy;
  
  const handlePurchase = () => {
    window.open(
      academic.button.url,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handlePurchase();
    }
  };

  return (
    <div 
      className={[styles.paymentTierOne, className].join(" ")}
      role="listitem"
      aria-labelledby="academic-tier-title"
    >
      <div className={styles.paymentTierOneChild} role="presentation" />
      <div className={styles.academicContent}>
        <h2 id="academic-tier-title" className={styles.academic}>{academic.title}</h2>
        <div className={styles.academicPricing} aria-label="Pricing information">
          <b className={styles.priceSymbol}>{academic.pricing.price}</b>
          <b className={styles.perLabchip}>{academic.pricing.subtitle}</b>
        </div>
        <button 
          className={styles.signUp} 
          onClick={handlePurchase}
          onKeyPress={handleKeyPress}
          aria-label={`Purchase ${academic.title} plan`}
        >
          <div className={styles.purchaseNow}>{academic.button.text}</div>
        </button>
      </div>
      <div className={styles.academicDetails}>
        <b className={styles.chatsPerMonthContainer}>
          {academic.features.slice(0, 2).map((feature, index) => (
            <p key={index} className={styles.chatsPerMonth}>{feature}</p>
          ))}
        </b>
        <div className={styles.securityHighlight}>
          <div className={styles.divider} role="presentation" />
          <div className={styles.chatsPerMonthContainer}>
            {academic.features[2]}
          </div>
          <div className={styles.asteriskNote}>
            {academic.features[3]}
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentTierOne.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierOne;
