import styles from "./FeatureBox.module.css";

const FeatureBox = ({ className = "", header, text, icon }) => {
  return (
    <article 
      className={[styles.talkContainer, className].join(" ")}
      tabIndex="0"
    >
      <div className={styles.headerContainerWrapper}>
        <h2 className={styles.headerContainer}>
          <span
            className={styles.header}
            dangerouslySetInnerHTML={{ __html: header }}
          />
        </h2>
      </div>
      <div 
        className={styles.featureDetails}
        aria-label="Feature description"
      >
        <b className={styles.feature}>{text}</b>
      </div>
      <div className={styles.talkContainerInner}>
        <img 
          className={styles.frameChild} 
          loading="lazy" 
          alt="" 
          src={icon}
          role="presentation" 
        />
      </div>
      <div 
        className={styles.orangeBorderBottom}
        aria-hidden="true"
      />
    </article>
  );
};

export default FeatureBox;
