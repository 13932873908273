import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import styles from './AppNoteArticle.module.css';

import { appnoteItems } from './AppNotes';

function AppNoteArticle() {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const article = appnoteItems.find(item =>
    item.title.toLowerCase().replace(/\s+/g, '-') === slug
  );

  useEffect(() => {
    if (article) {
      console.log("Article found:", article); // Add this line
      console.log("Download URL:", article.downloadUrl); // Add this line
      import(`../content/articles/${article.contentFile}`)
        .then(res => {
          fetch(res.default)
            .then(response => response.text())
            .then(text => setContent(text));
        })
        .catch(err => console.log(err));
    }
  }, [article]);

  if (!article) {
    return <div>Article not found</div>;
  }

  const handleOpenWithLabKick = () => {
    if (article.redirectId) {
      window.open(`https://app.labkick.ai/?${article.redirectId}=true`, '_blank');
    }
  };

  const handleImageClick = (src) => {
    setFullscreenImage(src);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  const handleDownload = () => {
    if (article.downloadUrl) {
      window.open(article.downloadUrl, '_blank');
    }
  };

  const handleKeyPress = (e, action) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      action();
    }
  };

  return (
    <main className={styles.container}>
      <h1 className={styles.title}>{article.title}</h1>
      <section className={styles.metaContainer} aria-label="Article metadata">
        <div className={styles.leftContent}>
          <div className={styles.authorInfo} role="contentinfo">
            <img
              src={article.image}
              alt={`${article.author}'s profile`}
              className={styles.avatar}
            />
            <span className={styles.authorName}>{article.author}</span>
            <time className={styles.date} dateTime={article.date}>{article.date}</time>
          </div>
          <nav className={styles.tags} aria-label="Article tags">
            {article.tags.map((tag, index) => (
              <span
                key={index}
                className={styles.tag}
                data-type={tag}
                role="tag"
              >
                {tag}
              </span>
            ))}
          </nav>
        </div>
        {/* <button 
          className={styles.openButton} 
          onClick={handleOpenWithLabKick}
          onKeyPress={(e) => handleKeyPress(e, handleOpenWithLabKick)}
          aria-label="Open with LabKick"
        >
          <img 
            src="/LabKick_Favicons_All_LabKick_Fav_192.svg" 
            alt="" 
            className={styles.buttonLogo} 
            aria-hidden="true"
          />
          Open with LabKick
        </button> */}
      </section>
      <hr className={styles.divider} />
      <article className={styles.content}>
        <ReactMarkdown
          className={styles.content}
          components={{
            img: ({ node, ...props }) => (
              <img
                {...props}
                className={styles.contentImage}
                onClick={() => handleImageClick(props.src)}
                onKeyPress={(e) => handleKeyPress(e, () => handleImageClick(props.src))}
                role="button"
                tabIndex="0"
                aria-label={`View ${props.alt || 'image'} in full screen`}
              />
            )
          }}
        >
          {content}
        </ReactMarkdown>
      </article>
      {article.downloadUrl ? (
        <button
          className={styles.openButton}
          onClick={handleDownload}
          onKeyPress={(e) => handleKeyPress(e, handleDownload)}
          aria-label="Download article files"
        >
          <img
            src="/LabKick_Favicons_All_LabKick_Fav_192.svg"
            alt=""
            className={styles.buttonLogo}
            aria-hidden="true"
          />
          Download Files
        </button>
      ) : (
        <p role="alert">No download URL available</p>
      )}
      {fullscreenImage && (
        <dialog
          className={styles.fullscreenOverlay}
          onClick={handleCloseFullscreen}
          role="dialog"
          aria-label="Image preview"
          open
        >
          <img
            src={fullscreenImage}
            alt="Full screen preview"
            className={styles.fullscreenImage}
          />
          <button
            className={styles.closeButton}
            onClick={handleCloseFullscreen}
            aria-label="Close image preview"
          >
            &times;
          </button>
        </dialog>
      )}
    </main>
  );
}

export default AppNoteArticle;
