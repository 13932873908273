import GroupComponent from "./GroupComponent";
import PropTypes from "prop-types";
import styles from "./BenefitItem.module.css";
import { benefitItemCopy } from "./copy";

const BenefitItem = ({ className = "" }) => {
  return (
    <section 
      className={[styles.benefitItem, className].join(" ")}
      aria-labelledby="benefits-heading"
    >
      <div className={styles.questions} role="region">
        <header className={styles.questionTitle}>
          <h1 id="benefits-heading" className={styles.gotQuestionsWere}>
            {benefitItemCopy.heading}
          </h1>
        </header>
        <div 
          className={styles.faq}
          role="list"
          aria-label="Frequently Asked Questions"
        >
          {benefitItemCopy.faqs.map((faq, index) => (
            <GroupComponent
              key={index}
              question={faq.question}
              answer={faq.answer}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

BenefitItem.propTypes = {
  className: PropTypes.string,
};

export default BenefitItem;
