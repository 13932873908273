import React, { forwardRef, useState } from "react";
import CustomButton from "./Button";
import FeatureBox from "./FeatureBox";
import PropTypes from "prop-types";
import styles from "./TopSection.module.css";
import { topSectionCopy } from "./copy";

const TopSection = forwardRef(({ className = "" }, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleSignUp = () => {
    console.log("Sign up button clicked");
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  const handleLogIn = () => {
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  const handleVideoClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleVideoClick();
    }
  };

  return (
    <section 
      ref={ref} 
      className={[styles.frameParent, className].join(" ")}
      aria-label="Main hero section"
    >
      <div className={styles.backgroundImageParent}>
        <div className={styles.backgroundImage} aria-hidden="true"></div>
        <div className={styles.headerWrapper}>
          <header className={styles.header}>
            <img
              className={styles.logoIcon}
              alt="LabKick Logo"
              src="/logo.svg"
            />

            <nav className={styles.signUpLogin} aria-label="Account access">
              <CustomButton
                propBackgroundColor="transparent"
                propHeight="3.125rem"
                // propFlex="1"
                propBorderRadius="31px"
                propBackground="linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea)"
                propPadding="8px 13px 8px 18px"
                filledButtonCTA="sign up"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleSignUp}
                aria-label="Sign up for an account"
              />
              <CustomButton
                propBackgroundColor="#FF8C00"
                propHeight="3.125rem"
                // propFlex="0.7564"
                propBorderRadius="31px"
                propBackground="#FF8C00"
                propPadding="8px 25px"
                filledButtonCTA="log in"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleLogIn}
                aria-label="Log in to your account"
              />
            </nav>
          </header>

          <div className={styles.sidekickContentWrapper}>
            <div className={styles.backgroundContainer} aria-hidden="true">
              <img alt="" src="/logo_with_lines.svg" />
            </div>
            <div className={styles.introBigContainer}>
              <div className={styles.introBig}>
                <h1 className={styles.meetYourDna}>
                  {topSectionCopy.mainHeading}
                </h1>
              </div>
              <div className={styles.introTextBodyContainer}>
                <b className={styles.introTextBody}>
                  <p className={styles.introText}>
                    {topSectionCopy.introText}
                  </p>
                </b>
              </div>
            </div>
          </div>

          <div 
            className={styles.videoSection}
            aria-label="Product demonstration video"
          >
            <div className={styles.videoPlaceholder}>
              {isPlaying ? (
                <video 
                  className={styles.heroVideo}
                  autoPlay
                  controls
                  src="https://labkickarticles.s3.us-east-2.amazonaws.com/lk-intro.mp4"
                  aria-label="Product demonstration"
                />
              ) : (
                <div 
                  className={styles.heroImagevideo} 
                  onClick={handleVideoClick}
                  onKeyPress={handleKeyPress}
                  role="button"
                  tabIndex={0}
                  aria-label="Play video"
                >
                  <img
                    className={styles.iconPlayCircle}
                    alt="Play video button"
                    src="/-icon-play-circle.svg"
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.customButtonWrapper}>
            <CustomButton
              filledButtonCTA="get started"
              onClick={handleLogIn}
              aria-label="Get started with LabKick"
            />
          </div>

          <div 
            className={styles.examplesWithIcons}
            role="list"
            aria-label="Key features"
          >
            {topSectionCopy.features.map((feature, index) => (
              <div key={index} role="listitem">
                <FeatureBox
                  header={feature.header}
                  text={feature.text}
                  icon={`/${["group-48", "lightbulb", "group-52", "group-46", "group-41", "broom"][index]}.svg`}
                />
              </div>
            ))}
          </div>

          <div 
            className={styles.dividerText}
            role="complementary"
            aria-label="Motivational message"
          >
            <h2 className={styles.canYouKickContainer}>
              <span>{topSectionCopy.dividerText.part1}</span>
              <span className={styles.span}>{` `}</span>
              <span className={styles.yesYouCan}>{topSectionCopy.dividerText.part2}</span>
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
});

TopSection.propTypes = {
  className: PropTypes.string,
};

export default TopSection;
