import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.css";

const CustomButton = ({
  className = "",
  propBackgroundColor,
  propHeight,
  propBorderRadius,
  propBackground,
  propPadding,
  filledButtonCTA,
  propTextAlign,
  propTextDecoration,
  onClick,
  ariaLabel,
}) => {
  const CustomButtonStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
      height: propHeight,
      borderRadius: propBorderRadius,
      background: propBackground,
      padding: propPadding,
    };
  }, [
    propBackgroundColor,
    propHeight,
    propBorderRadius,
    propBackground,
    propPadding,
  ]);

  const filledButtonCTAStyle = useMemo(() => {
    return {
      textAlign: propTextAlign,
      textDecoration: propTextDecoration,
    };
  }, [propTextAlign, propTextDecoration]);

  return (
    <button
      className={[styles.customButton, className].join(" ")}
      style={CustomButtonStyle}
      onClick={onClick}
      aria-label={ariaLabel || filledButtonCTA}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick?.(e);
        }
      }}
    >
      <span className={styles.filledButtonCta} style={filledButtonCTAStyle}>
        {filledButtonCTA}
      </span>
    </button>
  );
};

CustomButton.propTypes = {
  className: PropTypes.string,
  filledButtonCTA: PropTypes.string,
  onClick: PropTypes.func,

  /** Style props */
  propBackgroundColor: PropTypes.any,
  propHeight: PropTypes.any,
  propBorderRadius: PropTypes.any,
  propBackground: PropTypes.any,
  propPadding: PropTypes.any,
  propTextAlign: PropTypes.any,
  propTextDecoration: PropTypes.any,
  ariaLabel: PropTypes.string,
};

export default CustomButton;
